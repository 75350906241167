import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

const About = () => {
  return (
    <>
      <h2 className="head-text mb-5">
        I Know that <span>Good Code</span> <br />
        means <span>Good Business</span>
      </h2>

      <div style={{ maxWidth: "100%" }} className="mt-5">
        <main id="main">
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-4">
                  <img
                    src={images.about05}
                    className="img-fluid w-100 mt-5"
                    alt=""
                  />
                </div>
                <div className="col-lg-8 pt-4 pt-lg-0 content">
                  <h3>MERN Stack Developer &amp; Software Developer</h3>
                  <p className="fst-italic">
                    Hey! My name is Aziz and I am a MERN Stack developer.
                  </p>

                  <p>
                    My journey into the world of coding began in 2019, but it
                    was in 2021 that I truly ascended to a professional tier,
                    starting my career as a Software Engineer at Capgemini. My
                    experience, however, isn't confined to development alone.
                    I've also held diverse IT roles at Capgemini, which has
                    furnished me with a well-rounded skill set in technology.
                    
                    <br/><br/> Currently, I'm part of the forward-thinking team at Reply
                    IT. Here, my primary mission is to engineer accessible and
                    inclusive digital solutions for premier clients such as
                    Volkswagen, BMW, and Audi. My multidisciplinary background
                    and continued growth in this dynamic environment validate my
                    commitment to delivering quality, innovation, and
                    inclusivity in every project I undertake.
                    <div className="my-2">
                      Here are a few technologies I've been working with
                      recently:
                    </div>
                    <div className="d-flex skills">
                      <ul className="mr-5">
                        <li>
                          <i class="fa-solid fa-play"></i> Javascript
                        </li>
                        <li>
                          <i class="fa-solid fa-play"></i> Typescript
                        </li>
                        <li>
                          <i class="fa-solid fa-play"></i> React
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <i class="fa-solid fa-play"></i> Node.js
                        </li>
                        <li>
                          <i class="fa-solid fa-play"></i> MongoDB
                        </li>
                        <li>
                          <i class="fa-solid fa-play"></i> Dialogflow
                        </li>
                      </ul>
                    </div>
                  </p>
                  <div className="btns ">
                    <a href="#contact" className="btn">
                      Contact Me
                    </a>
                    <a href="#resume" className="download btn">
                      See My Resume
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
