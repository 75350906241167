import React from "react";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaLinkedin, FaGithub } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a target="_blank" href="https://github.com/AzizbekTokhirjonov">
        <FaGithub />
      </a>
    </div>
    <div>
      <a
        target="_blank"
        href="https://www.facebook.com/profile.php?id=100008825597100"
      >
        <FaFacebookF />
      </a>
    </div>
    <div>
      <a target="_blank" href="https://www.linkedin.com/in/aziztokhirjonov/">
        <FaLinkedin />
      </a>
    </div>
  </div>
);

export default SocialMedia;
