import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { AppWrap, MotionWrap } from "../../wrapper";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FcSmartphoneTablet } from "react-icons/fc";
import "./Footer.scss";

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const form = useRef();
  const userID = process.env.REACT_APP_EMAILJS_USER_ID;

  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  emailjs.init(userID);
  const sendEmail = () => {
    emailjs.sendForm(serviceID, templateID, form.current, userID).then(
      (result) => {
        setSuccess(true);
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);

        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    sendEmail();
  };
  return (
    <>
      <h2 className="head-text">Take a coffee & chat with me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <MdEmail color="#5c1151" />
          <a href="mailto:aziztokhirjonov@gmail.com" className="p-text">
            aziztokhirjonov@gmail.com
          </a>
        </div>
        <div className="app__footer-card">
          <FcSmartphoneTablet />
          <a href="tel:+971 58 990 6394" className="p-text">
            +971 58 990 6394
          </a>
        </div>
        <div className="app__footer-card">
          <FaLinkedin color="#0E76A8" />
          <a
            href="https://www.linkedin.com/in/aziztokhirjonov/"
            target="_blank"
            className="p-text"
          >
            Azizbek Tokhirjonov
          </a>
        </div>
        <div className="app__footer-card">
          <FaGithub />
          <a
            href="https://github.com/AzizbekTokhirjonov"
            target="_blank"
            className="p-text"
          >
            AzizbekTokhirjonov
          </a>
        </div>
      </div>

      <form
        ref={form}
        className="app__footer-form app__flex"
        onSubmit={handleSubmit}
      >
        <div className="app__flex">
          <input
            className="p-text"
            type="text"
            placeholder="Your Name"
            name="from_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="app__flex">
          <input
            className="p-text"
            type="email"
            placeholder="Your Email"
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <textarea
            className="p-text"
            placeholder="Your Message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="p-text" onSubmit={handleSubmit}>
          {!loading ? "Send Message" : "Sending..."}
        </button>
      </form>
      {success && (
        <div class="alert alert-success" role="alert">
          Yey, I got your message! Thanks for contacting me!
        </div>
      )}
      <div className="copyright">
        <p className="p-text">@2022 AZIZ</p>
        <p className="p-text">All rights reserved</p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
