import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Work.scss";

const data = [
  {
    codeLink: "#",
    description:
      "Digital Human Helga merges Uneeq's human-like interaction capabilities with Dialogflow CX's conversational prowess. Engineered to assist individuals with various disorders, Helga sets a new standard for AI-driven healthcare. More information available on request.",
    imgUrl: images.dh,
    projectLink: "https://www.infocancro.pt/alice-assistente-virtual",
    tags: ["React JS"],
    title: "Digital Human Helga",
    id: "422a360d-9f49-4e8f-9777-d80ccda9a413",
    type: "works",
  },
  {
    codeLink: "#",
    description:
      "Co-developed with a seasoned colleague, TMS PRO is a trailblazing Truck Management System application. Offering a full suite of features, this app modernizes the logistics and fleet management industry. Further information available on request.",
    imgUrl: images.truck,
    projectLink:
      "https://www.youtube.com/watch?v=EZJ_-vh_fLA&ab_channel=AzizTokhirjonov",
    tags: ["React JS", "Web App", "UI/UX"],
    title: "TMS PRO",
    id: "422a360d-9f49-4e8f-9777-d80ccda9a414",
    type: "works",
  },
  {
    codeLink: "#",
    description:
      "Advanced Chrome extension for Amazon Relay to refresh the loadboard and notify when new loads are available. Autobook and fastbook are implemented. Many more features with integration with TMS PRO.",
    imgUrl: images.turbo,
    projectLink:
      "https://www.youtube.com/watch?v=rqXoY3BNXrA&ab_channel=TurboRefresh",
    tags: ["Automation"],
    title: "Turbo Refresh",
    id: "422a360d-9f49-4e8f-9777-d80ccda9a415",
    type: "works",
  },
  {
    codeLink: "#",
    description:
      "WavCompare is a cutting-edge credit-based portal for car rentals and sales. With dual admin and user interfaces, it serves as a one-stop solution for car enthusiasts. Further details are available upon request.",
    imgUrl: images.wav,
    projectLink: "https://customer.wavcompare.com/",
    tags: ["React JS", "Web App", "UI/UX"],
    title: "WavCompare",
    id: "422a360d-9f49-4e8f-9777-d80ccda9a412",
    type: "works",
  },
  {
    codeLink: "#",
    description:
      "Don Trans LLC's offical webpage and its CEO optimization. With its mobile and web responsiveness and elegant design, the website brands the company to its customers. Further details are available upon request.",
    imgUrl: images.don_trans,
    projectLink: "https://dontransllc.com/",
    tags: ["React JS", "Web App", "UI/UX"],
    title: "Don Trans LLC",
    id: "422a360d-9f49-4e8f-9777-sdgdsgsgdsdg",
    type: "works",
  },
  {
    codeLink: "#",
    description:
      "Adiora's Wellness app is a fully comprehensive massage booking system with both good looking webpage with cool booking system and dashboard to manage the boookings. Further details are available upon request.",
    imgUrl: images.massage,
    projectLink: "https://adioraswellness.com/",
    tags: ["React JS", "Web App", "UI/UX"],
    title: "Adiora's Wellness",
    id: "422a360d-9f49-4e8f-9777-sdgsdgsdgdsg",
    type: "works",
  },
];

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    setWorks(data);
    setFilterWork(data);
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        My Creative <span>Portfolio</span> Section
      </h2>

      <div className="app__work-filter">
        {["UI/UX", "Web App", "React JS", "Automation", "All"].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === item ? "item-active" : ""
              }`}
            >
              {item}
            </div>
          )
        )}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div className="app__work-img app__flex">
              <img src={work.imgUrl} alt={work.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                {work.projectLink !== "#" && (
                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                  </a>
                )}
                {
                  (work,
                  work.codeLink !== "#" && (
                    <a href={work.codeLink} target="_blank" rel="noreferrer">
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className="app__flex"
                      >
                        <AiFillGithub />
                      </motion.div>
                    </a>
                  ))
                }
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className="app__work-tag app__flex">
                <p className="p-text">{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "portfolio",
  "app__primarybg"
);
