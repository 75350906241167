import React from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Resume.scss";

const Resume = () => {
  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <main id="main">
          <section id="resume" className="resume">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2 className="text-center mb-4 head-text">Resume</h2>
                <p className="text-center" style={{ fontSize: "20px" }}>
                  I work to make a difference!
                </p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <h3 className="resume-title">Summary</h3>
                  <div className="resume-item pb-0">
                    <h4>Azizbek Tokhirjonov</h4>
                    <p>
                      <em>
                        Highly skilled Software Engineer seeking a position in a
                        dynamic, growth-oriented organization to leverage
                        expertise in software development, testing, and agile
                        methodologies. Brings a strong foundation in computer
                        science and exceptional communication skills to drive
                        innovation and efficiency.
                      </em>
                    </p>
                    <p>
                      <ul>
                        <li>Andijon, Uzbekistan</li>
                        <li>+998914981999</li>
                        <li>aziztokhirjonov@gmail.com</li>
                      </ul>
                    </p>
                  </div>

                  <h3 className="resume-title">Education</h3>
                  <div className="resume-item">
                    <h4> Full Stack Development</h4>
                    <h5>2021</h5>
                    <p>
                      <em>Strive School</em>
                    </p>
                    <p>
                      Improved my coding skills and expanded my knowledge on
                      different technologies.
                    </p>
                  </div>
                  <div className="resume-item">
                    <h4>
                      Bachelor Degree &amp; International Relations, Business
                      and Economy
                    </h4>
                    <h5>2018 - 2021</h5>
                    <p>
                      <em>WSB University, Poland</em>
                    </p>
                    <p>
                      Studied business, finance, marketing and economy with
                      fully-funded scholarship by Europian Commission.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="resume-title">Professional Experience</h3>

                  <div className="resume-item">
                    <h4>Software Engineer</h4>
                    <h5>2023 - Present</h5>
                    <p>
                      <em>Reply IT, Katowice, Poland</em>
                    </p>
                    <p>
                      <ul>
                        <li>
                          Spearheaded the design, development, and optimization
                          of financial and app solutions for automotive industry
                          leaders such as Volkswagen, BMW, and AUDI using the
                          MERN Stack.
                        </li>
                        <li>
                          Engineered high-performance RESTful APIs and
                          facilitated seamless third-party integrations,
                          enhancing system interoperability.
                        </li>
                        <li>
                          Translated intricate business requirements into
                          scalable and robust full-stack applications,
                          contributing significantly to achieving key business
                          goals.
                        </li>
                        <li>
                          Utilized a suite of modern tools and
                          platforms—including Gitlab, Jira, Google Cloud, and
                          AWS architect efficient, scalable, and reliable
                          software solutions.
                        </li>
                      </ul>
                    </p>
                  </div>

                  <div className="resume-item">
                    <h4>Software Engineer</h4>
                    <h5>2021 - 2023</h5>
                    <p>
                      <em>Capgemini, Krakow, Poland</em>
                    </p>
                    <p>
                      <ul>
                        <li>
                          Developed and maintained MERN Stack-based chatbots and
                          intelligent automation solutions for high-profile
                          clients, including Roche.
                        </li>
                        <li>
                          Designed, deployed, and optimized RESTful APIs,
                          ensuring high availability and performance, while
                          integrating external APIs to extend functionality.
                        </li>
                        <li>
                          Executed frontend and backend development in alignment
                          with specifications from business analysts, achieving
                          high client satisfaction.
                        </li>
                        <li>
                          Employed a wide range of technologies such as Gitlab,
                          Jira, Google Cloud, and Dialogflow ES/CX to enhance
                          development efficiency and product reliability.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a
                  href="https://drive.google.com/file/d/1zdgAVw7_vhQa16Py6FcUHXlC6stZ8KUR/view?usp=sharing"
                  target="_blank"
                  className="download btn"
                >
                  Download
                </a>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Resume, "app__resume"),
  "resume",
  "app__primarybg"
);
